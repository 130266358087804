<template>
  <div style="display: inline-block;">
    <b-btn
      v-if="gridProps.row['status'] === 'aguardandoconfirmacao'"
      v-b-tooltip.hover
      title="Enviar convite"
      variant="none"
      class="btn btn-none rounded-pill icone-acao acoes-usuario"
      @click="enviarConvite(gridProps.row)"
    >
      <feather-icon
        icon="SendIcon"
        size="20"
      />
    </b-btn>
    <!-- <b-btn
      v-b-tooltip.hover
      title="Vincular Empresas"
      variant="none"
      class="btn-outline-info btn-none rounded-pill icone-acao acoes-usuario"
      @click="$bvModal.show('m-vincularEmpresas')"
    >
      <feather-icon
        icon="PaperclipIcon"
        size="16"
      />
    </b-btn>

    <b-btn
      v-b-tooltip.hover
      title="Transferir Empresas"
      variant="none"
      class="btn-outline-info btn-none rounded-pill icone-acao acoes-usuario"
    >
      <feather-icon
        icon="ChevronsRightIcon"
        size="16"
      />
    </b-btn>

    <b-btn
      v-b-tooltip.hover
      title="Configurar Permissões"
      variant="none"
      class="btn-outline-info btn-none rounded-pill icone-acao acoes-usuario"
    >
      <feather-icon
        icon="ClipboardIcon"
        size="16"
      />
    </b-btn> -->

    <!-- <b-btn
      v-if="gridProps.row['administrador']"
      v-b-tooltip.hover
      title="Remover Acesso Administrador"
      variant="none"
      class="btn-outline-success btn-none rounded-pill icone-acao acoes-usuario"
      @click="show"
    >
      <feather-icon
        color="#008000"
        icon="LockIcon"
        size="16 "
      />
    </b-btn>
    <b-btn
      v-else-if="!gridProps.row['administrador']"
      v-b-tooltip.hover
      variant="none"
      class="btn-outline-danger btn-none rounded-pill icone-acao acoes-usuario"
      title="Tornar Usuário Administrador"
      @click="show"
    >
      <feather-icon
        color="#FF0000"
        icon="UnlockIcon"
        size="16 "
      />
    </b-btn> -->
  </div>
</template>

<style lang="scss">
.acoes-usuario {
  margin-right: 0.3rem;
  padding: 0.4rem;
}
</style>

<script>

import api from '@/app/usuario/shared/services/api'

export default {
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    enviarConvite(linha) {
      api.enviarConvite(linha.id)
        .then(() => {
          this.$sucesso('Convite enviado para o e-mail do usuário')
          this.$refs.usuarioInternoList.refresh()
        })
        .catch(err => {
          this.$erroResponse(err.response)
        })
    },
  },
}
</script>
